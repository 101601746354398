import React, { useEffect, useState } from "react";
import logo from "./logo.svg";

import { BrowserRouter } from "react-router-dom";
import Container from "./Container";
import Header from "./components/Header/Header";
import Menu from "./components/Menu/Menu";
import Footer from "./components/Footer/Footer";
function App() {
  const [menu, setMenu] = useState(false);

  

  return (
    <>
      <BrowserRouter>
        <div className="" style={{ height: "100px !important" }}>
          <Header menu={() => setMenu(!menu)} open={menu} />

          {menu && (
            <Menu
              color={"black"}
              textColor={"white"}
              close={() => setMenu(!menu)}
            />
          )}

          <Container />

          <Footer
            color={"black"}
            textColor={"white"}
            name={"DLC Tech S.R.L."}
            address={"via Montecchia 22/A"}
            city={"Selvazzano Dentro"}
            country={"Padova (PD)"}
            phone={"+39 0498234396"}
          />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
