import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Artist from "./routes/Artist";

const Container = () => {
  return (
    <Routes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/Artist/:name"} element={<Artist />} />
      {/* <Route path={"/prezzofisso"} element={<PrezzoFisso />} /> */}
      {/* <Route path={"/allacarta"} element={<AllaCarta />} /> */}
      {/* <Route path={"/delgiorno"} element={<DelGiorno />} /> */}
    </Routes>
  );
};

export default Container;
