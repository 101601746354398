import { useEffect, useState } from "react";

import logo from "../../assets/factoryTattoo2.png";

import burger from "../../assets/lotties/burgerMenu.json";
import styles from "./Header.module.scss";
import { useNavigate } from "react-router-dom";
import Menu from "../Menu/Menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
interface HeaderProps {
  menu: () => void;
  open: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const redirect = useNavigate();
  const [menu, setMenu] = useState(false);

  return (
    <>
      <div
        className={`d-flex flex-row align-items-center justify-content-between p-5 col-12 color-white  ${styles.container}`}
      >
        <div
          className={` col-6 d-flex flex-row align-items-center justify-content-center ${styles.logoContainer}`}
        >
          <img src={logo} onClick={() => redirect("/")} />
        </div>
        <div className={`col-2  text-center`}>
          {props.open && (
            <FontAwesomeIcon
              icon={faXmark as IconProp}
              onClick={() => {
                props.menu();
              }}
              style={{ fontSize: "3rem" }}
            />
          )}
          {!props.open && (
            <FontAwesomeIcon
              icon={faBars as IconProp}
              onClick={() => {
                props.menu();
              }}
              style={{ fontSize: "3rem" }}
            />
          )}
        </div>
        {/* <div className={`${styles.center}  col-2`} >
          <div></div>
        </div> */}
      </div>
    </>
  );
};
export default Header;
