import { useEffect, useState } from "react";

import website from "../assets/website.png";
import Ponch from "../assets/MarcoPonch.jpeg";
import Baldan from "../assets/MarcoBaldan.jpeg";
// import Canali from "";

import styles from "./Home.module.scss";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const Home: React.FC = () => {
  const redirect = useNavigate();
  const [menu, setMenu] = useState(false);
  useEffect(() => {
    console.log(window.innerHeight);
  }, [window.innerHeight]);

  return (
    <>
      {/* <Header menu={() => setMenu(!menu)} /> */}
      <div className="d-flex  flex-row align-items-center justify-content-center col-12    p-5">
        <div className={`col-12   ${styles.imageContainer}`}>
          <img src={website} />
        </div>
      </div>
      <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-7 color-black   p-5">
        <div className="d-flex flex-row align-items-center justify-content-center ">
          <span className="small-title ">Our Artists</span>
        </div>
        <div className={`col-12 mt-7  ${styles.imageForArtist}`}>
          <img src={Ponch} onClick={() => redirect("/artist/Ponch")} />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-5">
          <span className="sub-title-ml">Marco Ponch</span>
        </div>
        <div className={`col-12 mt-10  ${styles.imageForArtist}`}>
          <FontAwesomeIcon
            icon={faUser as IconProp}
            style={{ height: "98%" }}
            onClick={() => redirect("/artist/Canali")}
          />
          {/* <img src={Baldan} onClick={() => redirect("/artist/2")} /> */}
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-5">
          <span className="sub-title-ml">Tommaso Canali</span>
        </div>
        <div className={`col-12 mt-10  ${styles.imageForArtist}`}>
          <img src={Baldan} onClick={() => redirect("/artist/Baldan")} />
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-5">
          <span className="sub-title-ml">Marco Baldan</span>
        </div>
      </div>
      {/* <Footer color={"black"} textColor={"white"} name={""} address={""} city={""} country={""} phone={""} /> */}
    </>
  );
};
export default Home;
