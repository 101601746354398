import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import website from "../assets/website.png";
import logo from "../assets/LOGO_FINALE1.png";

import ponch1 from "../assets/Ponch1.jpeg";
import ponch2 from "../assets/Ponch2.jpeg";
import ponch3 from "../assets/Ponch3.jpeg";

import canali1 from "../assets/canali1.jpeg";
import canali2 from "../assets/canali2.jpeg";
import canali3 from "../assets/canali3.jpeg";

import baldan1 from "../assets/baldan1.jpeg";
import baldan2 from "../assets/baldan2.jpeg";
import baldan3 from "../assets/baldan3.jpeg";

import styles from "./Artist.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../components/Header/Header";
import {
  faFacebook,
  faInstagram,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Footer from "../components/Footer/Footer";
import Menu from "../components/Menu/Menu";

const Artist: React.FC = () => {
  const redirect = useNavigate();
  const params = useParams();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <Header menu={() => setMenu(!menu)} /> */}

      <div className="d-flex flex-column align-items-center justify-content-center col-12 mt-7    p-5">
        <div className="d-flex flex-row align-items-center justify-content-center ">
          <span
            className="color-black"
            style={{ fontSize: "3.8rem", fontWeight: "bold" }}
          >
            {params.name === "Ponch" && "Marco Ponch"}
            {params.name === "Canali" && "Tommaso Canali"}
            {params.name === "Baldan" && "Marco Baldan"}
          </span>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center mt-7 ">
          <span
            className="color-black text-center"
            style={{ fontSize: "2rem" }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Accumsan
            sit amet nulla facilisi morbi tempus. Interdum consectetur libero id
            faucibus nisl tincidunt eget. Nisl suscipit adipiscing bibendum est
            ultricies integer quis auctor. Tortor at auctor urna nunc. Felis
            donec et odio pellentesque diam volutpat commodo. Sapien nec
            sagittis aliquam malesuada bibendum.
          </span>
        </div>
        <div className="d-flex flex-row align-items-center  justify-content-around col-12  mt-7 ">
          <span>
            <FontAwesomeIcon
              icon={faInstagram as IconProp}
              style={{ fontSize: "3.5rem" }}
            />
          </span>
          <span>
            <FontAwesomeIcon
              icon={faFacebook as IconProp}
              style={{ fontSize: "3.5rem" }}
            />
          </span>
          <span>
            <FontAwesomeIcon
              icon={faTiktok as IconProp}
              style={{ fontSize: "3.5rem" }}
            />
          </span>
        </div>

        <div className="d-flex flex-row align-items-center justify-content-center col-12 mt-10 mb-10 ">
          <div className=" d-flex   flex-column justify-content-start  col-6 ">
            <div className={`  col-12  p-1  ${styles.singleImage}`}>
              {params.name === "Ponch" && <img src={ponch1} />}
              {params.name === "Canali" && <img src={canali1} />}
              {params.name === "Baldan" && <img src={baldan1} />}
            </div>
            <div className={`col-12   p-1  ${styles.singleImage}`}>
              {params.name === "Ponch" && <img src={ponch2} />}
              {params.name === "Canali" && <img src={canali2} />}
              {params.name === "Baldan" && <img src={baldan2} />}
            </div>
            <div className={`col-12   p-1  ${styles.singleImage}`}></div>
            {/* <div className={`  col-12 p-1  ${styles.singleImage}`}>
              <img src={logo} />
            </div> */}
          </div>
          <div className=" d-flex   flex-column justify-content-start  col-6 ">
            <div className={`  col-12  p-1  ${styles.singleImage}`}>
              {params.name === "Ponch" && <img src={ponch3} />}
              {params.name === "Canali" && <img src={canali3} />}
              {params.name === "Baldan" && <img src={baldan3} />}
            </div>
            <div className={`col-12   p-1  ${styles.singleImage}`}></div>
            {/* <div className={`col-12   p-1  ${styles.singleImage}`}>
              <img src={logo} />
            </div> */}
            {/* <div className={`  col-12 p-1  ${styles.singleImage}`}>
              <img src={website} />
            </div> */}
          </div>
        </div>
      </div>
      {/* <Footer
        color={"black"}
        textColor={"white"}
        name={"DLC Tech S.R.L."}
        address={"via Montecchia 22/A"}
        city={"Selvazzano Dentro"}
        country={"Padova (PD)"}
        phone={"+39 0498234396"}
      /> */}
      {/* {menu && (
        <div style={{}}>
          <Menu
            color={"black"}
            textColor={"white"}
            close={() => setMenu(!menu)}
          />
        </div>
      )} */}
    </>
  );
};
export default Artist;
