import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import website from "../assets/website.png";
import logo from "../assets/LOGO_FINALE1.png";

import styles from "./Menu.module.scss";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";

interface MenuProps {
  color: string;
  textColor: string;
  close: () => void;
}

const Menu: React.FC<MenuProps> = (props) => {
  const redirect = useNavigate();

  useEffect(() => {
    console.log(window.innerHeight);
  }, [window.innerHeight]);

  return (
    <>
      <div
        className={`${styles.menuContainer}`}
        style={{ color: `${props.textColor}` }}
      >
        <Header menu={() => props.close()} open={true} />

        <div
          className={`d-flex flex-column  justify-content-center align-items-center ${styles.menuPage}`}
        >
          <div className="sub-title-ml p-5 ">
            <span
              className=""
              onClick={() => {
                redirect("/");
                props.close();
              }}
            >
              Home
            </span>
          </div>
          <div className="sub-title-ml p-5 ">
            <span
              onClick={() =>
                window.location.replace("https://reviews.dlctech.it/client/9")
              }
            >
              Lascia una recensione
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
export default Menu;
