import { useEffect, useState } from "react";

import styles from "./Footer.module.scss";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope, faMessage } from "@fortawesome/free-regular-svg-icons";

import logoDLC from "../../assets/LOGO_WHITE.png";

interface FooterProps {
  color: string;
  textColor: string;
  name: string;
  address: string;
  city: string;
  country: string;
  phone: string;
}

const Footer: React.FC<FooterProps> = (props) => {
  const redirect = useNavigate();

  useEffect(() => {
    console.log(window.innerHeight);
  }, [window.innerHeight]);

  return (
    <>
      <div
        className="d-flex flex-column align-items-center justify-content-center p-5"
        style={{
          backgroundColor: `${props.color}`,
          color: `${props.textColor}`,
        }}
      >
        <div className="d-flex flex-row align-items-center justify-content-center  ">
          <span className="text-center">{props.name}</span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center ">
          <span className="text-center">{props.address}</span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center ">
          <span className="text-center">
            {props.city}, {props.country}
          </span>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-center mt-4">
          <span className="text-center">{props.phone}</span>
        </div>
        <div className="d-flex flex-row align-items-center  justify-content-around col-12  mt-7 ">
          <span>
            <FontAwesomeIcon
              icon={faInstagram as IconProp}
              style={{ fontSize: "3.5rem" }}
            />
          </span>
          <span>
            <FontAwesomeIcon
              icon={faFacebook as IconProp}
              style={{ fontSize: "3.5rem" }}
            />
          </span>
          <span>
            <FontAwesomeIcon
              icon={faEnvelope as IconProp}
              style={{ fontSize: "3.5rem" }}
            />
          </span>
        </div>
        <div
          className={` d-flex flex-row align-items-center justify-content-center  mt-7 ${styles.poweredRow}`}
        >
          <span>powered by</span>
          <img src={logoDLC} />
        </div>
      </div>
    </>
  );
};
export default Footer;
